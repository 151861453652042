@mixin loading() {
    .loading & {
        @content;
    }
}

@mixin appearance() {
    .appearing & {
        @content;
    }
}

@mixin for-tablet {
    @media screen and (max-width: 1000px){
        @content;
    }
}

@mixin for-sm {
    @media screen and (max-width: 720px){
        @content;
    }
}

@mixin for-xs {
    @media screen and (max-width: 500px){
        @content;
    }
}
