@font-face {
    font-family: 'Euclid Circular B';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: local('EuclidCircularB-Regular'), url(../fonts/euclidcircularb-regular.woff2) format('woff2');
}

@font-face {
    font-family: 'Euclid Circular B';
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    src: local('EuclidCircularB-Semibold'), url(../fonts/euclidcircularb-semibold.woff2) format('woff2');
}
