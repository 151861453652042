.container {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 90px; // phone offset 70px + gap 20px

    @include for-tablet {
        flex-direction: column;
        gap: 0;
    }
}

.description {
    padding: 240px 0 240px 80px;
    min-width: 50vw;

    @include for-tablet {
        align-self: flex-start;
        padding: 80px 80px 0;
    }

    @include for-sm {
        padding: 60px 40px 0;
    }

    @include for-xs{
        padding: 40px 20px 0;
    }
}

.logotype {
    display: block;
    width: 155px;
    height: auto;

    @include for-sm {
        width: 120px;
    }

    @include for-xs {
        width: 91px;
    }
}

.title {
    opacity: 0;
    animation: fade-in-and-zoom $time 1s forwards;
    color: $color-white;
    margin: 40px 0;

    @include for-sm {
        margin: 30px 0;
    }

    @include for-xs {
        margin: 20px 0;
    }
}

.coverage {
    animation: opacity $time 4.8s forwards;
    opacity: 0;
    font-family: $heading;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 2.6px;
    color: $color-white;
    display: flex;
    align-items: center;

    & > span {
        &:after {
            content: "·";
            margin: 0 .8em;
        }

        &:last-child {
            &:after {
                content: none;
            }
        }
    }

    @include for-xs {
        font-size: 8px;
        letter-spacing: 1.6px;
    }
}

.highlight {
    color: $color-light-green;
}

.double-spacing {
    margin-left: .8em;
    letter-spacing: 11.7px;


    @include for-xs {
        letter-spacing: 7.2px;
    }
}

.visualization {
    position: relative;
    margin-right: -60px;

    @include for-tablet {
        margin: 60px 0 200px;
        padding: 0 80px 0 calc(80px + 5%);
    }

    @include for-sm {
        padding: 0 40px 0 calc(40px + 5%);
    }

    @include for-xs {
        padding: 0 0 0 calc(20px + 5%);
        margin: 40px -160px 200px 0;
    }
}

.phone {
    opacity: 0;
    animation: fade-in-from-left $time 2.8s forwards;
    position: absolute;
    bottom: -7%;
    left: -70px;
    z-index: 2;

    display: block;
    width: 196px;
    height: auto;

    border-radius: 28px;
    box-shadow: 0 8.547px 29.915px 0 rgba(0, 0, 0, 0.35), 0 0.855px 1.709px 0 rgba(0, 0, 0, 0.15);

    @include for-tablet {
        border-radius: 0;
        box-shadow: none;
        max-width: 20%;
        left: 70px;
    }

    @include for-sm {
        left: 40px;
    }

    @include for-xs {
        left: 20px;
    }
}

.desktop {
    opacity: 0;
    animation: fade-in-from-bottom $time 2s forwards;
    display: block;
    width: 775px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 11.071px 83.036px 0 #0085FF, 55.357px 38.75px 138.393px 0 rgba(0, 255, 224, 0.35), 0 1.107px 2.214px 0 #003565;

    @include for-tablet {
        max-width: 100%;
    }
}

.footer {
    animation: fade-in-from-bottom $time 3.8s forwards;
    opacity: 0;
    width: max-content;
    position: relative;
    z-index: 2;
    margin-top: -230px;
    padding-left: 80px;

    @include for-tablet {
        margin-top: -110px;
        padding: 0 80px 50px;
    }

    @include for-sm {
        padding: 0 40px 40px;
    }

    @include for-xs{
        margin-top: -140px;
        padding: 0 20px 20px;
    }


    * {
        @extend %body-3-regular;
        color: $color-white-06;

        strong {
            font-weight: 700;
        }
    }
}

.belongs {
    color: $color-white;
    margin-bottom: 16px;
}

.company-info {
    display: grid;
    grid-gap: 20px 32px;
    grid-template-columns: 1fr 1fr;

    @include for-xs {
        grid-template-columns: 1fr;
        grid-gap: 16px;
    }
}

.footer-bottom {
    display: flex;
    gap: 32px;

    @include for-xs {
        flex-direction: column;
        gap: 16px;
    }
}

.footer-bottom {
    margin-top: 24px;

    @include for-xs {
        margin-top: 16px;
    }
}

.btn-privacy-policy {
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    background-color: transparent;
    color: $color-primary-main;
    width: max-content;
    cursor: pointer;
}

.copyright {

}
