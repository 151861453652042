@use "../mixins";

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

body {
    font-family: $heading;
    font-size: 14px;
    line-height: 20px;
    font-style: normal;
    font-weight: 400;
    color: #fff;
    background-color: $color-text-primary;
    padding: 0;
    margin: 0;
    min-height: 100svh;
}

a[href] {
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    color: inherit;
    text-decoration: underline;
}

.container {
    margin: 0;
    min-height: 100svh;
    max-width: 100vw;
    flex-grow: 1;
    position: relative;
    overflow: hidden;

    @include mixins.loading() {
        visibility: hidden;
        pointer-events: none;
    }
}

dialog {
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
    height: 100svh;
    width: 100vw;
    overflow-y: scroll;

    .dialog-container {
        background-color: $color-text-primary;
        padding: 40px;
        position: relative;

        @include for-tablet {
            padding: 30px;
        }

        @include for-sm {
            padding: 20px;
        }
    }

    .text-content {
        background-color: $color-white;
        padding: 80px;
        border-radius: 32px;

        @include for-tablet {
            padding: 40px;
        }

        @include for-sm {
            padding: 40px 30px;
        }

        @include for-xs {
            padding: 40px 20px;
        }
    }

    .close-dialog {
        cursor: pointer;
        background-color: transparent;
        border: none;
        padding: 0;
        position: absolute;
        right: 80px;
        top: 80px;
        display: block;
        width: 24px;
        height: 24px;

        @include for-tablet {
            right: 60px;
            top: 60px;
        }

        @include for-sm {
            right: 40px;
            top: 40px;
        }

        &:before,
        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 12px;
            width: 28px;
            height: 2px;
            background-color: $color-black;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }
}

h1 {
    @extend %h1;
}

h2 {
    @extend %h2;
}

h3 {
    @extend %h3;
}

h4 {
    @extend %h4;
}

li,
p,
a {
    @extend %text;
}

th,
td {
    @extend %text;

    @include for-sm {
        font-size: 13px;

        & * {
            font-size: 13px;
        }

        ul {
            padding-left: 1em;
        }
    }
}

.text-content {

    h2 {
        margin: .8em 0;
    }

    h4 {
        margin: .8em 0 .4em;
    }

    p {
        margin-bottom: 1em;
    }

    *:first-child {
        margin-top: 0;
    }

    a {
        color:$color-primary-main;
        transition: color .3s ease-in-out;

        &:hover {
            color: darken($color-primary-main, 20%);
        }
    }

    table {
        th {
            font-weight: bold;
            text-align: left;
            border-bottom: 1px solid $color-black;
        }

        td {
            vertical-align: top;
            padding-right: 5px;
            padding-left: 0;

            &:last-child {
                padding-left: initial;
            }
        }

        @include for-sm {
            margin-left: -10px;
            margin-right: -30px;
        }
    }
}
