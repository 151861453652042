@font-face {
  font-family: Euclid Circular B;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: local(EuclidCircularB-Regular), url("euclidcircularb-regular.27c9c653.woff2") format("woff2");
}

@font-face {
  font-family: Euclid Circular B;
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: local(EuclidCircularB-Semibold), url("euclidcircularb-semibold.b9871f10.woff2") format("woff2");
}

h1 {
  font-family: Euclid Circular B, sans-serif;
  font-size: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
}

@media screen and (max-width: 720px) {
  h1 {
    font-size: 54px;
    line-height: 1.1;
  }
}

@media screen and (max-width: 500px) {
  h1 {
    font-size: 34px;
    line-height: 1.1;
  }
}

h2 {
  font-family: Euclid Circular B, sans-serif;
  font-size: 54px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.1;
}

@media screen and (max-width: 720px) {
  h2 {
    font-size: 40px;
    line-height: 1.1;
  }
}

@media screen and (max-width: 500px) {
  h2 {
    font-size: 34px;
    line-height: 1.1;
  }
}

h3 {
  font-family: Euclid Circular B, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
}

@media screen and (max-width: 720px) {
  h3 {
    font-size: 25px;
    line-height: 1.1;
  }
}

@media screen and (max-width: 500px) {
  h3 {
    font-size: 18px;
    line-height: 1.1;
  }
}

h4 {
  font-family: Euclid Circular B, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}

@media screen and (max-width: 500px) {
  h4 {
    font-size: 20px;
  }
}

th, td, li, p, a {
  font-family: IBM Plex Sans, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

@media screen and (max-width: 720px) {
  th, td, li, p, a {
    font-size: 16px;
  }
}

@media screen and (max-width: 500px) {
  th, td, li, p, a {
    font-size: 14px;
  }
}

.footer * {
  font-family: Euclid Circular B, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

@media screen and (max-width: 500px) {
  .footer * {
    font-size: 12px;
  }
}

@keyframes fade-in-from-bottom {
  from {
    opacity: 0;
    transform: translateY(60px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-from-left {
  from {
    opacity: 0;
    transform: translateX(-160px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes zoom-in {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fade-in-and-zoom {
  from {
    opacity: 0;
    transform-origin: 0 100%;
    transform: translateY(60px)scale(.0001);
  }

  to {
    opacity: 1;
    transform: translateY(0)scale(1);
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #0000004d;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #00000080;
}

body {
  color: #fff;
  background-color: #14171f;
  min-height: 100svh;
  margin: 0;
  padding: 0;
  font-family: Euclid Circular B, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

a[href] {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-decoration: underline;
}

.container {
  flex-grow: 1;
  max-width: 100vw;
  min-height: 100svh;
  margin: 0;
  position: relative;
  overflow: hidden;
}

.loading .container {
  visibility: hidden;
  pointer-events: none;
}

dialog {
  z-index: 999;
  width: 100vw;
  height: 100svh;
  margin: 0;
  padding: 0;
  position: fixed;
  inset: 0;
  overflow-y: scroll;
}

dialog .dialog-container {
  background-color: #14171f;
  padding: 40px;
  position: relative;
}

@media screen and (max-width: 1000px) {
  dialog .dialog-container {
    padding: 30px;
  }
}

@media screen and (max-width: 720px) {
  dialog .dialog-container {
    padding: 20px;
  }
}

dialog .text-content {
  background-color: #fff;
  border-radius: 32px;
  padding: 80px;
}

@media screen and (max-width: 1000px) {
  dialog .text-content {
    padding: 40px;
  }
}

@media screen and (max-width: 720px) {
  dialog .text-content {
    padding: 40px 30px;
  }
}

@media screen and (max-width: 500px) {
  dialog .text-content {
    padding: 40px 20px;
  }
}

dialog .close-dialog {
  cursor: pointer;
  background-color: #0000;
  border: none;
  width: 24px;
  height: 24px;
  padding: 0;
  display: block;
  position: absolute;
  top: 80px;
  right: 80px;
}

@media screen and (max-width: 1000px) {
  dialog .close-dialog {
    top: 60px;
    right: 60px;
  }
}

@media screen and (max-width: 720px) {
  dialog .close-dialog {
    top: 40px;
    right: 40px;
  }
}

dialog .close-dialog:before, dialog .close-dialog:after {
  content: "";
  background-color: #000;
  width: 28px;
  height: 2px;
  position: absolute;
  top: 12px;
  left: 0;
}

dialog .close-dialog:before {
  transform: rotate(45deg);
}

dialog .close-dialog:after {
  transform: rotate(-45deg);
}

@media screen and (max-width: 720px) {
  th, td, th *, td * {
    font-size: 13px;
  }

  th ul, td ul {
    padding-left: 1em;
  }
}

.text-content h2 {
  margin: .8em 0;
}

.text-content h4 {
  margin: .8em 0 .4em;
}

.text-content p {
  margin-bottom: 1em;
}

.text-content :first-child {
  margin-top: 0;
}

.text-content a {
  color: #0085ff;
  transition: color .3s ease-in-out;
}

.text-content a:hover {
  color: #005099;
}

.text-content table th {
  text-align: left;
  border-bottom: 1px solid #000;
  font-weight: bold;
}

.text-content table td {
  vertical-align: top;
  padding-left: 0;
  padding-right: 5px;
}

.text-content table td:last-child {
  padding-left: initial;
}

@media screen and (max-width: 720px) {
  .text-content table {
    margin-left: -10px;
    margin-right: -30px;
  }
}

.container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  justify-content: space-between;
  align-items: center;
  gap: 90px;
  display: flex;
}

@media screen and (max-width: 1000px) {
  .container {
    flex-direction: column;
    gap: 0;
  }
}

.description {
  min-width: 50vw;
  padding: 240px 0 240px 80px;
}

@media screen and (max-width: 1000px) {
  .description {
    align-self: flex-start;
    padding: 80px 80px 0;
  }
}

@media screen and (max-width: 720px) {
  .description {
    padding: 60px 40px 0;
  }
}

@media screen and (max-width: 500px) {
  .description {
    padding: 40px 20px 0;
  }
}

.logotype {
  width: 155px;
  height: auto;
  display: block;
}

@media screen and (max-width: 720px) {
  .logotype {
    width: 120px;
  }
}

@media screen and (max-width: 500px) {
  .logotype {
    width: 91px;
  }
}

.title {
  opacity: 0;
  color: #fff;
  margin: 40px 0;
  animation: 1s cubic-bezier(.11, .79, .43, 1.46) 1s forwards fade-in-and-zoom;
}

@media screen and (max-width: 720px) {
  .title {
    margin: 30px 0;
  }
}

@media screen and (max-width: 500px) {
  .title {
    margin: 20px 0;
  }
}

.coverage {
  opacity: 0;
  letter-spacing: 2.6px;
  color: #fff;
  align-items: center;
  font-family: Euclid Circular B, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  animation: 1s cubic-bezier(.11, .79, .43, 1.46) 4.8s forwards opacity;
  display: flex;
}

.coverage > span:after {
  content: "·";
  margin: 0 .8em;
}

.coverage > span:last-child:after {
  content: none;
}

@media screen and (max-width: 500px) {
  .coverage {
    letter-spacing: 1.6px;
    font-size: 8px;
  }
}

.highlight {
  color: #00f0ff;
}

.double-spacing {
  letter-spacing: 11.7px;
  margin-left: .8em;
}

@media screen and (max-width: 500px) {
  .double-spacing {
    letter-spacing: 7.2px;
  }
}

.visualization {
  margin-right: -60px;
  position: relative;
}

@media screen and (max-width: 1000px) {
  .visualization {
    margin: 60px 0 200px;
    padding: 0 80px 0 calc(80px + 5%);
  }
}

@media screen and (max-width: 720px) {
  .visualization {
    padding: 0 40px 0 calc(40px + 5%);
  }
}

@media screen and (max-width: 500px) {
  .visualization {
    margin: 40px -160px 200px 0;
    padding: 0 0 0 calc(20px + 5%);
  }
}

.phone {
  opacity: 0;
  z-index: 2;
  border-radius: 28px;
  width: 196px;
  height: auto;
  animation: 1s cubic-bezier(.11, .79, .43, 1.46) 2.8s forwards fade-in-from-left;
  display: block;
  position: absolute;
  bottom: -7%;
  left: -70px;
  box-shadow: 0 8.547px 29.915px #00000059, 0 .855px 1.709px #00000026;
}

@media screen and (max-width: 1000px) {
  .phone {
    box-shadow: none;
    border-radius: 0;
    max-width: 20%;
    left: 70px;
  }
}

@media screen and (max-width: 720px) {
  .phone {
    left: 40px;
  }
}

@media screen and (max-width: 500px) {
  .phone {
    left: 20px;
  }
}

.desktop {
  opacity: 0;
  border-radius: 8px;
  width: 775px;
  height: auto;
  animation: 1s cubic-bezier(.11, .79, .43, 1.46) 2s forwards fade-in-from-bottom;
  display: block;
  box-shadow: 0 11.071px 83.036px #0085ff, 55.357px 38.75px 138.393px #00ffe059, 0 1.107px 2.214px #003565;
}

@media screen and (max-width: 1000px) {
  .desktop {
    max-width: 100%;
  }
}

.footer {
  opacity: 0;
  z-index: 2;
  width: max-content;
  margin-top: -230px;
  padding-left: 80px;
  animation: 1s cubic-bezier(.11, .79, .43, 1.46) 3.8s forwards fade-in-from-bottom;
  position: relative;
}

@media screen and (max-width: 1000px) {
  .footer {
    margin-top: -110px;
    padding: 0 80px 50px;
  }
}

@media screen and (max-width: 720px) {
  .footer {
    padding: 0 40px 40px;
  }
}

@media screen and (max-width: 500px) {
  .footer {
    margin-top: -140px;
    padding: 0 20px 20px;
  }
}

.footer * {
  color: #fff9;
}

.footer * strong {
  font-weight: 700;
}

.belongs {
  color: #fff;
  margin-bottom: 16px;
}

.company-info {
  grid-gap: 20px 32px;
  grid-template-columns: 1fr 1fr;
  display: grid;
}

@media screen and (max-width: 500px) {
  .company-info {
    grid-gap: 16px;
    grid-template-columns: 1fr;
  }
}

.footer-bottom {
  gap: 32px;
  display: flex;
}

@media screen and (max-width: 500px) {
  .footer-bottom {
    flex-direction: column;
    gap: 16px;
  }
}

.footer-bottom {
  margin-top: 24px;
}

@media screen and (max-width: 500px) {
  .footer-bottom {
    margin-top: 16px;
  }
}

.btn-privacy-policy {
  color: #0085ff;
  cursor: pointer;
  background-color: #0000;
  border: none;
  outline: none;
  width: max-content;
  margin: 0;
  padding: 0;
}

/*# sourceMappingURL=index.c364457e.css.map */
