$heading: 'Euclid Circular B', sans-serif;
$text: 'IBM Plex Sans', sans-serif;

%h1 {
    font-family: $heading;
    font-size: 80px;
    font-style: normal;
    font-weight: 600;
    line-height: 1;

    @include for-sm {
        font-size: 54px;
        line-height: 1.1;
    }

    @include for-xs {
        font-size: 34px;
        line-height: 1.1;
    }
}

%h2 {
    font-family: $heading;
    font-size: 54px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.1;

    @include for-sm {
        font-size: 40px;
        line-height: 1.1;
    }

    @include for-xs {
        font-size: 34px;
        line-height: 1.1;
    }
}

%h3 {
    font-family: $heading;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;

    @include for-sm {
        font-size: 25px;
        line-height: 1.1;
    }

    @include for-xs {
        font-size: 18px;
        line-height: 1.1;
    }
}

%h4 {
    font-family: $heading;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;

    @include for-xs {
        font-size: 20px;
    }
}

%text {
    font-family: $text;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;

    @include for-sm {
        font-size: 16px;
    }

    @include for-xs {
        font-size: 14px;
    }
}

%body-3-regular {
    font-family: $heading;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    @include for-xs {
        font-size: 12px;
    }
}
