$time: 1s cubic-bezier(.11,.79,.43,1.46);

@keyframes fade-in-from-bottom {
    from {
        opacity: 0;
        transform: translateY(60px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-in-from-left {
    from {
        opacity: 0;
        transform: translateX(-160px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes zoom-in {
    from {
        opacity: 0;
        transform: scale(0);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fade-in-and-zoom {
    from {
        opacity: 0;
        transform-origin: left bottom;
        transform: translateY(60px) scale(.0001);
    }

    to {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}

@keyframes opacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
